<template>
 <div id="shop">
    <div class="shop-wrap">
       <div class="shop-left">
         <div class="mall" v-if="sub==1">
            <h3>道具商城</h3>
            <div class="prop-item" v-for="(item,index) in propsList" :key="index">
                <img v-show="item.propType==1" src="@/assets/imgs/job_top.png" />
                <img v-show="item.propType==2" src="@/assets/imgs/resumeRefresh.png" />
                <p>
                  <span>{{item.propTypeName}}</span>
                  <label>{{item.propDescribe}}</label>
                </p>
                <div class="btn-buy" @click="openPurchase(item)">立即购买</div>
            </div>
         </div>
         <div class="mall" v-if="sub==2">
            <h3>我的道具</h3>
            <el-tabs v-model="onState"  @tab-click="toggelProp">
                <el-tab-pane label="未使用" name="0"></el-tab-pane>
                <el-tab-pane label="使用中" name="1"></el-tab-pane>
                <el-tab-pane label="已使用" name="2"></el-tab-pane>
                <el-tab-pane label="已过期" name="3"></el-tab-pane>
            </el-tabs>
            <div class="prop-item" v-for="(item,index) in userPropList" :key="index">
                <img v-show="item.propType==1" src="@/assets/imgs/job_top.png" />
                <img v-show="item.propType==2" src="@/assets/imgs/resumeRefresh.png" />
                <p>
                  <span v-show="item.propType==1">急聘<em>( {{item.subExpirationNum}}天/次 )</em></span>
                  <span v-show="item.propType==2">置顶<em>( {{item.subExpirationNum}}天/次 )</em></span>
                  <label>{{item.propDescribe}}</label>
                </p>
                <div class="btn-buy" @click="propUsage(item)" v-show="item.onState==0">使用道具</div>
            </div>
         </div>
         <div class="mall" v-if="sub==3">
            <h3>购买记录</h3>
            <el-table
                :data="purchaseList"
                >
                <el-table-column
                  prop="integral"
                  label="消费积分"
                 >
                </el-table-column>
                 <el-table-column
                  prop="describe"
                  label="描述"
                 >
                </el-table-column>
             </el-table>
         </div>
       </div>
       <div class="shop-right">
          <div class="props">
             <p @click="toggle(1)" :class="{activeSub:sub==1}">道具商城</p>
             <p @click="toggle(2)" :class="{activeSub:sub==2}">我的道具</p>
             <p @click="toggle(3)" :class="{activeSub:sub==3}">消费记录</p>
          </div>
          <div class="wallet">
             <h4>我的钱包<span @click="openRecharge">充值记录</span></h4>
             <p><label>{{userInfo.accountAmount}}</label><span>人才币</span></p>
             <div class="coin"><i class="el-icon-coin"></i><span>{{userInfo.accountIntegral}}积分</span></div>
             <div class="btn-buy" @click="openWallet" v-show="obtainPaySwitch">
                购买人才币
             </div>
          </div>
       </div>
    </div>
    <div class="purchase">
       <el-dialog
        :visible.sync="purchaseShow"
        width="1000px"
       >
       <div class="purchase-left">
          <h3>
           <span v-show="propInfo.propType==1">急聘</span>
           <span v-show="propInfo.propType==2">置顶</span>
           <label>{{propInfo.propDescribe}}</label>
          </h3>
          <div class="row-title">
            <img src="@/assets/imgs/select-server.png">
            <span>支付方式</span>
          </div>
          <div class="row-select">
            <el-radio-group v-model="purchaseType">
              <el-radio  label="2">积分支付</el-radio>
              <el-radio  label="1">人才币支付</el-radio>
            </el-radio-group >
          </div>
          <div class="row-title">
            <img src="@/assets/imgs/select-server.png">
            <span>选择服务</span>
          </div>
          <div class="server-item"  @click="toggleDiscount(index,item)" :class="{activeDiscount:discount==index}" v-for="(item,index) in propInfo.sysPropPriceList" :key="index">
              <div class="discount" v-show="item.discount!=0">{{item.discount}}折优惠</div>
              <strong>{{item.subExpirationNum}}</strong>
              <label>天/次</label>
              <span v-show="purchaseType==1">{{item.propAmount}}人才币</span>
              <span v-show="purchaseType==2">{{item.propIntegral}}积分</span>
          </div>
       </div>
       <div class="purchase-right">
          <div class="need-pay">
            <span>商品价格</span>
            <label v-show="purchaseType==1">{{sysPropInfo.propAmount}}人才币</label>
            <label v-show="purchaseType==2">{{sysPropInfo.propIntegral}}积分</label>
          </div>
          <div class="discount-pay">
            <span>优惠后价格</span>
            <label v-show="purchaseType==1">{{sysPropInfo.discountAmount||sysPropInfo.propAmount}}人才币</label>
            <label v-show="purchaseType==2">{{sysPropInfo.discountIntegral||sysPropInfo.propIntegral}}积分</label>
          </div>
          <div class="purchaseBtn" @click="purchaseProps">
            立即购买
          </div>
       </div>
       
      </el-dialog>
    </div>
    <div class="walletDialog">
      <el-dialog
        title="人才币充值"
        :visible.sync="walletShow"
        width="600px"
       >  
        <div class="row-title">
          <img src="@/assets/imgs/select-server.png">
          <span>请选择人才币充值数量</span>
        </div>
        <div class="moneyList">
           <div class="money-item"  @click="toggleWallet(index)" :class="{activeWallet:walletSub==index}"  v-for="(item,index) in moneyList" :key="index">
              <label>{{item}}人才币</label>
              <span>{{item}}元</span>
           </div>
        </div>
        <div class="row-title">
          <img src="@/assets/imgs/select-server.png">
          <span>其它数量</span>
        </div>
        <div class="other">
          ￥<el-input v-model="rechargeAmount" @blur="changeAmount"></el-input>
        </div>
        <div class="row-select">
          <el-radio-group v-model="payChannel" @change="changeAmount">
            <el-radio label="1"><div class="row-title"><img src="@/assets/imgs/wxPay.png"><p>微信</p></div></el-radio>
            <el-radio label="2"><div class="row-title"><img src="@/assets/imgs/zhifubao.png"><p>支付宝</p></div></el-radio>
            <el-radio label="3"><div class="row-title"><img src="@/assets/imgs/jianhang.png"><p>建行支付</p></div></el-radio>
          </el-radio-group >
        </div>
<!--        <div class="qrcode" ref="qrCodeUrl"></div>-->
        <div class="qrcode" id="qrcode" ref="qrcode"></div>
      </el-dialog>
    </div>
    <div class="walletDialog">
      <el-dialog
        title="充值记录"
        :visible.sync="rechargeShow"
        width="600px"
       >  
         <el-table
          :data="rechargeRList"
          >
          <el-table-column
            prop="createTime"
            label="充值时间"
            width="200"
            >
          </el-table-column>
          <el-table-column
            label="充值金额"
            >
            <template slot-scope="scope">
               ￥{{scope.row.orderAmount}}
             </template>
          </el-table-column>
          <el-table-column
            label="订单状态"
            >
             <template slot-scope="scope">
                <span v-show="scope.row.orderStatus== 'WAIT_BUYER_PAY'">待支付</span>
                <span v-show="scope.row.orderStatus== 'SUCCESS'">支付成功</span>
                <span v-show="scope.row.orderStatus== 'PROGRESS'">进行中</span>
                <span v-show="scope.row.orderStatus== 'FAIL'">支付失败</span>
             </template>
          </el-table-column>
           <el-table-column
            prop="oderCashbackIntegral"
            label="返现积分"
            >
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>
 </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import {getRechargeRecord, getPurchaseHistory, getObtainPaySwitch, getPropsList, getUserProp, propUsage, getPropsDetails, purchaseProps, getUserInfo, rechangeMember, getPayStatus} from "@/api/index.js";
export default {
  name: "shop",
  data() {
    return {
      propsList:[],
      userPropList:[],
      purchaseList:[],
      sub:1,
      onState:'0',
      purchaseShow:false,
      propInfo:{},
      purchaseType:'2',
      payChannel:'1',
      discount:0,
      sysPropInfo:{},
      userInfo:{},
      walletShow:false,
      moneyList:[100,200,300,500,1000,2000],
      walletSub:0,
      rechargeAmount:'100',
      obtainPaySwitch:false,
      rechargeShow:false,
      rechargeRList:[],
      timer:null
    }
  },
  mounted() {
    this.getPropsList()
    this.getUserProp()
    this.getUserInfo()
    this.getObtainPaySwitch()
    this.getPurchaseHistory()
    this.getRechargeRecord()

    this.timer = setInterval(() => {
      if(this.walletShow){
        setTimeout(this.getPayStatuss, 0);
      }
    }, 3000);

  },
  //页面切除后解除定时
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    openRecharge(){
      this.rechargeShow = true
    },
    getPayStatuss(){
      getPayStatus({rechargeAmount: this.rechargeAmount, payChannel: this.payChannel, channel: "2"}).then(res=>{
        if(res.data){
          this.$router.push({
            path: '/paySucess',
            query:{
              type:1
            }
          })
        }
      })
    },
    getRechargeRecord(){
      getRechargeRecord({
        orderStatus: "SUCCESS"
      }).then(res=>{
        this.rechargeRList = res.rows
      })
    },
    getPurchaseHistory(){
      getPurchaseHistory().then(res=>{
        this.purchaseList = res.rows
      })
    },
    getObtainPaySwitch(){
      getObtainPaySwitch().then(res=>{
         this.obtainPaySwitch = res.data.obtainPaySwitch
      })
    },
    changeAmount(){
      /*if(this.$refs.qrCodeUrl){
        this.$refs.qrCodeUrl.innerHTML = ''
      }*/
      if(document.getElementById('qrcode') != null){
        document.getElementById('qrcode').innerHTML = "";
      }
      this.rechangeMember()
    },
    toggleWallet(index){
      this.walletSub = index
      this.rechargeAmount = this.moneyList[index]
      this.rechangeMember()
    },
    rechangeMember(){
      rechangeMember({
        channel:2,
        payChannel: this.payChannel,
        rechargeAmount:this.rechargeAmount
      }).then(res=>{

        new QRCode("qrcode", {
          width: 132,
          height: 132,
          colorLight: '#ffffff', //二维码背景色
          correctLevel: QRCode.CorrectLevel.H, //纠错等级
          text: res.data.code_url // 生成二维码的链接
        });

         /*new QRCode(this.$refs.qrCodeUrl, {
          text: res.data.code_url, //可以是链接，也可以是文本
          width: 132, //二维码的宽度
          height: 132, //二维码的高度
          colorLight: '#ffffff', //二维码背景色
          correctLevel: QRCode.CorrectLevel.H //纠错等级
        })*/
      })
    },
    openWallet(){
      this.walletShow = true
      this.rechangeMember()
    },
    getUserInfo() {
      getUserInfo().then(res => {
        this.userInfo = res.data  
      })
    },
    purchaseProps(){
      purchaseProps({
        purchaseType:this.purchaseType,
        sysPropPriceId:this.sysPropInfo.sysPropPriceId
      }).then(res=>{
        if(res.code==200){
        this.$message({
          message: '购买成功',
          type: 'success'
        })
        this.purchaseShow = false
        this.sub = '2'
        this.getUserProp()
      }else{
        this.$message({
          message:res.msg,
          type: 'warning'
        })
      }
      })
    },
    toggleDiscount(index,item){
      this.discount = index
      this.sysPropInfo = item
    },
    openPurchase(item){
      getPropsDetails({
        id:item.id
      }).then(res=>{
       this.propInfo = res.data
       this.sysPropInfo  =  this.propInfo.sysPropPriceList[0] 
      })
      this.purchaseShow = true
    },
   propUsage(item){
     propUsage({
      userPropId:item.id,
      positionInfoId:item.positionInfoId||''
     }).then(res=>{
      if(res.code==200){
        this.$message({
          message: '使用成功',
          type: 'success'
        })
        this.getUserProp()
      }else{
        this.$message({
          message:res.msg,
          type: 'warning'
        })
      }
     })
   },
   getPropsList(){
    getPropsList().then(res=>{
      this.propsList = res.rows
    })
   },
   getUserProp(){
      getUserProp({
        onState:this.onState
      }).then(res=>{
        this.userPropList = res.rows
      })
   },
   toggle(index){
    this.sub = index
   },
   toggelProp(){
    this.getUserProp()
   }
  }
};
</script>

<style lang="scss" scoped>
#shop{
  background-color: #f6f6f8;
  overflow: hidden;
  padding-bottom: 20px;
}
.shop-wrap{
  width: 1200px;
  margin:20px auto;
}
.shop-left{
  width: 880px;
  float: left;
  background: #fff;
  min-height: 400px;
  h3{
    font-size: 24px;
    font-weight: 600;
    color: #222;
    line-height: 26px;
    background: #fff;
    padding: 22px 30px;
    border-radius: 4px 4px 0 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #f5f5f5;
  }
  .prop-item{
    margin-top: 30px;
    overflow: hidden;
    img{
      width: 60px;
      height: 60px;
      display: block;
      float: left;
      margin-left: 30px;
    }
    p{
      float: left;
      margin-left:15px;
      margin-top: 4px;
      span{
        display: block;
        color: #414a60;
        font-size: 18px;
        font-weight: 600;
      }
      label{
        margin-top: 10px;
        display: block;
        font-size: 14px;
        color: #999;
      }
      em{
        font-style: normal;
        font-weight: 100;
        font-size: 15px;
        display: inline-block;
        margin-left: 10px;
      }
    }
    .btn-buy{
      float: right;
      width: 110px;
      text-align: center;
      height: 28px;
      line-height: 28px;
      padding: 0;
      background: linear-gradient(270deg,#ff8554,#ff394b);
      border-radius: 2px;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
      margin-right: 20px;
      margin-top: 5px;
    }
  }
}
.shop-right{
  float: left;
  .props{
    background: #fff;
    width: 260px;
    min-height: 100px;
    margin-bottom: 16px;
    border-radius: 12px;
    margin-left: 15px;
    padding:5px 20px;
    p{
      border-bottom: 1px solid #f5f5f5;
      padding-bottom: 15px;
      padding-top: 15px;
      cursor: pointer;
    }
    .activeSub{
      color: #0077FF;
    }
  }
  .wallet{
    background: #fff;
    width: 260px;
    height: 100px;
    margin-bottom: 16px;
    border-radius: 12px;
    margin-left: 15px;
    padding:20px;
    h4{
      font-weight: 100;
      color: #222;
      span{
        display:block;
        float: right;
        font-size: 13px;
        color: #0077FF;
        cursor: pointer;
        position: relative;
        top: 3px;
      }
    }
    p{
      margin-top: 10px;
      width: 120px;
      float: left;
      label{
        font-weight: 500;
        font-size: 25px;
      }
      span{
        font-size: 13px;
        display: inline-block;
        margin-left: 10px;
        color: #666;
      }
    }
    .coin{
      font-size: 15px;
      color: #999;
      margin-top: 18px;
      float: left;
      margin-bottom: 15px;
      span{
        display: inline-block;
        margin-left: 7px;
      }
    }
    .btn-buy{
      background-color: #fff;
    color: #ff6223;
    width: 244px;
    height: 33px;
    clear: both;
    border: 1px solid #ff6223;
    line-height: 33px;
    font-size: 13px;
    margin-top: 10px;
    text-align: center;
    cursor: pointer;
    }
  }
}
.purchase{
  .purchase-left{
        width: 580px;
        height: 300px;
    padding: 24px 0 30px 24px;
    float: left;;
    background-color: #fff;
      h3{
      font-weight: 100;
      margin-bottom:30px;
      margin-top: 10px;
      span{
            font-size: 16px;
      font-weight: 500;
      color: #222;
      }
      label{
        background: #eef0f6;
      border-radius: 12px;
      padding: 3px 15px;
      margin-left: 12px;
      font-size: 12px;
      font-weight: 400;
      color: #8d92a1;
      display: inline-block;
      }
    }
    .row-title{
      margin-top:24px;
      margin-bottom: 24px;
      overflow: hidden;
      img{
        float: left;
        width: 18px;
        height: 18px;
      }
      span{
        display: block;
        float: left;
            font-size: 14px;
    font-weight: 500;
    color: #414a60;
    margin-left: 8px;
      }
    }
    .row-select{
      clear: both;
      padding-left: 25px;
    }

    .server-item{
          width: 240px;
        height: 78px;
        line-height: 78px;
        background: #fff;
        border: 1px solid #e3e7ed;
        float: left;
        margin-bottom: 18px;
        position: relative;
        border-radius: 6px;
        cursor: pointer;
        padding: 0 16px;
        font-size: 14px;
        font-weight: 500;
        margin-right: 15px;
        .discount{
          position: absolute;
          left: 0;
    top: -10px;
    height: 20px;
    line-height: 20px;
    width: 50px;
    background: #5dd5c8;
    color: #fff;
    font-size: 11px;
    padding-left: 10px;
    border-radius: 11px 0 11px 0;
        }
        strong{
              font-size: 24px;
    color: #414a60;
        }
        label{
          color: #414a60;
          font-size: 13px;
          margin-left: 4px;
        }
        span{
          display: block;
          float: right;
           color: #414a60;
          font-size: 14px;
        }
    }
    .activeDiscount{
      border: 1px solid #5dd5c8;
      background-color: #f2fcfb;
    }
  }
  .purchase-right{
    float: left;
    width: 360px;
    height: 300px;
   .need-pay{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background: #fff;
    padding: 0 20px;
    margin-top: 70px;
    width:300px;
    margin-left: 24px;
    border-bottom: 1px dashed rgba(0,0,0,.2);
    label{
      color: #fc6c38;
    font-size: 16px;
    font-weight: 500;
    }
    span{
          color: #333;
    font-size: 14px;
    font-weight: 500;
    }
   }
   .discount-pay{
     position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background: #fff;
    padding: 0 20px;
    width:300px;
    margin-left: 24px;
    label{
      color: #333;
    font-size: 16px;
    font-weight: 500;
    }
    span{
          color: #333;
    font-size: 14px;
    font-weight: 500;
    }
   }
   .purchaseBtn{
    margin-left:25px;
    margin-top: 20px;
    height: 40px;
    line-height: 40px;
    width: 340px;
    background: rgb(238, 109, 109);
    color: #fff;
    text-align: center;
    cursor: pointer;
    border-radius:8px;
   }
  }
}
.walletDialog{
  /deep/.el-radio {
    display:inline-flex !important;
    align-items: center !important;
    .el-radio__input {
      display:inline-flex !important;
      align-items: center !important;
    }
  }

 .moneyList{
  overflow: hidden;
  margin-left: 25px;
 }
 .row-title{
      margin-top:10px;
      margin-bottom: 10px;
   display: inline-flex;
   align-items: center !important;
   overflow: visible;
      img{
        float: left;
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-right: 10px;
      }
      span{
        display: block;
        float: left;
            font-size: 14px;
    font-weight: 500;
    color: #414a60;
    margin-left: 8px;
      }
    }

 .money-item {
  width: 125px;
  border: 1px solid #e3e7ed;
  float: left;
  margin-right: 25px;
  border-radius:6px;
  margin-bottom: 20px;
  text-align: center;
  padding:13px;
  color: #414a60;
  font-size: 14px;
  cursor: pointer;
  label{
    display: block;
    margin-bottom: 3px;
    font-size: 16px;
  }
}
.activeWallet{
   border: 1px solid #FF2400;
  background: rgb(250, 229, 229);
    color: #FF2400;
}
.qrcode{
  width: 132px;
  margin: 20px auto;
}
}
.other{
     font-size: 24px;
    font-weight: 400;
    color: #fc6c38;
  padding-left: 25px;
  margin-bottom: 20px;
}
</style>
<style lang="scss">
.mall{
  .el-tabs {
    margin: 0 30px;
    .el-tabs__item{
      font-size: 15px;
    }
  }
  .el-table{
    padding: 0 20px;
  }
}
.purchase{
  .el-dialog{
     background-color: #f1f1f1;
     overflow: hidden;
  }
  .el-dialog__header,.el-dialog__footer{
    padding: 0;
  }

  .el-dialog__body{
    padding:0;
  }
}
.walletDialog{
   .el-dialog__body{
    padding:0 20px 20px;
  }
}
.other {
  .el-input{
    width: 200px;
    display: inline-block;
  }
  .el-input__inner{
    border: none;
    font-size: 24px;
    font-weight: 400;
    color: #fc6c38;
    width: 300px;
    border-bottom: 1px solid #e3e7ed;
  }
}
</style>